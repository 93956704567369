var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"page-title"},[_vm._v("学习辅助")]),(!_vm.isOffline)?_c('a-row',{staticClass:"rows"},[_c('a-col',{staticClass:"left required",attrs:{"span":"3"}},[_vm._v("开班设置")]),_c('a-col',{staticClass:"right",attrs:{"span":"20"}},[_c('a-radio-group',{staticClass:"radioGroup",model:{value:(_vm.formData.setOpenClass),callback:function ($$v) {_vm.$set(_vm.formData, "setOpenClass", $$v)},expression:"formData.setOpenClass"}},[_c('a-radio',{attrs:{"disabled":_vm.userStudy == '1',"value":"0"}},[_vm._v("无需设置开班时间")]),_c('a-radio',{attrs:{"disabled":_vm.userStudy == '1',"value":"1"}},[_vm._v("需要设置开班时间")])],1),(_vm.formData.setOpenClass=='1')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.Modal.open({
        title: '设置开班时间',
        width: '500px',
        type: 'SelectOpenClass'
      })}}},[_vm._v("新增班级")]):_vm._e()],1)],1):_vm._e(),(_vm.formData.setOpenClass=='1' && !_vm.isOffline)?_c('a-row',{staticClass:"rows",staticStyle:{"margin-top":"0"}},[_c('a-col',{staticClass:"right",attrs:{"span":"19","offset":3}},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item){ return item.uid; },"columns":_vm.columns1,"data-source":_vm.formData.courseOpenTimeList,"pagination":{
          total:_vm.total,
          current: _vm.openTimeNumber,
          pageSizeOptions: ['5','10','20','50'],
          defaultPageSize: _vm.openTimePageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return ("共" + total + "条")}}},on:{"change":_vm.onOpenTimePage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.openTimeNumber - 1) * _vm.openTimePageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item, row){return [(_vm.userStudy != '1' || !row.openTimeId)?_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){return _vm.$refs.Modal.open({
              title: '设置开班时间',
              width: '500px',
              type: 'SelectOpenClass',
              state: 2
            },row)}}},[_vm._v("编辑")]),_c('span',[_vm._v("|")]),_c('a',{on:{"click":function($event){return _vm.removeSelectOpenClass(row.uid)}}},[_vm._v("删除")])]):_c('span',[_vm._v("-")])]}}],null,false,1038026312)})],1)],1):_vm._e(),(_vm.isOffline)?[_c('a-row',{staticClass:"rows"},[_c('a-col',{staticClass:"left",class:_vm.isOffline?'required':'',attrs:{"span":"3"}},[_vm._v("培训场次")]),_c('a-col',{staticClass:"right",attrs:{"span":"20"}},[_c('span',{staticClass:"fontSize",staticStyle:{"color":"#999"}},[_vm._v("(该设置仅针对课程类别为线下培训时有效)")])])],1),_c('a-row',{staticClass:"rows"},[_c('a-col',{staticClass:"right",attrs:{"span":"19","offset":"3"}},[_c('a-radio-group',{model:{value:(_vm.formData.offlineSameAddr),callback:function ($$v) {_vm.$set(_vm.formData, "offlineSameAddr", $$v)},expression:"formData.offlineSameAddr"}},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v("所有场次在同一地点")]),(_vm.formData.offlineSameAddr=='1')?[_c('Province',{ref:"Province",attrs:{"value":_vm.addressValue},on:{"regionCity":_vm.FunProvince}}),_c('a-input',{staticStyle:{"width":"240px","margin-left":"8px"},attrs:{"placeholder":"请输入详细地址"},model:{value:(_vm.ProvinceAddress),callback:function ($$v) {_vm.ProvinceAddress=$$v},expression:"ProvinceAddress"}}),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.Modal.open({
              title: '新增场次',
              width: '500px',
              type: 'FixedPlaceTraining'
            })}}},[_vm._v("新增场次")]),_c('div',[_c('a-table',{staticClass:"table-template",staticStyle:{"margin-top":"10px"},attrs:{"rowKey":function (item){ return item.uid; },"columns":_vm.columns2,"data-source":_vm.returnFixedPlaceTrainingList},scopedSlots:_vm._u([{key:"operation",fn:function(item, row){return [(row.number == row.currentNumber)?[_c('a',{on:{"click":function($event){return _vm.$refs.Modal.open({
                      title: '编辑场次',
                      width: '500px',
                      type: 'FixedPlaceTraining',
                      state: 2
                    },row)}}},[_vm._v("编辑")]),_c('span',[_vm._v(" | ")]),_c('a',{on:{"click":function($event){return _vm.removeFixedPlaceTraining('FixedPlaceTrainingList',row.uid)}}},[_vm._v("删除")])]:_c('span',[_vm._v("-")])]}}],null,false,567185946)})],1)]:_vm._e(),_c('a-radio',{attrs:{"value":"0"}},[_vm._v("培训场次在不同地点")]),(_vm.formData.offlineSameAddr=='0')?[_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.Modal.open({
              title: '新增场次',
              width: '500px',
              type: 'NonFixedPlaceTraining'
            })}}},[_vm._v("新增场次")]),_c('div',[_c('a-table',{staticClass:"table-template",staticStyle:{"margin-top":"10px"},attrs:{"rowKey":function (item){ return item.uid; },"columns":_vm.columns3,"data-source":_vm.returnNonFixedPlaceTrainingList},scopedSlots:_vm._u([{key:"operation",fn:function(item, row){return [_c('a',{on:{"click":function($event){return _vm.$refs.Modal.open({
                    title: '新增场次',
                    width: '500px',
                    type: 'NonFixedPlaceTraining',
                    state: 2
                  },row)}}},[_vm._v("编辑")]),_c('span',[_vm._v(" | ")]),_c('a',{on:{"click":function($event){return _vm.removeFixedPlaceTraining('NonFixedPlaceTrainingList',row.uid)}}},[_vm._v("删除")])]}}],null,false,3412384038)})],1)]:_vm._e()],2)],1)],1)]:_vm._e(),_c('a-row',{staticClass:"rows"},[_c('a-col',{staticClass:"left",attrs:{"span":"3"}},[_vm._v("课件维护")])],1),(1=='1')?_c('a-row',{staticClass:"rows",staticStyle:{"margin-top":"0"}},[_c('a-col',{staticClass:"right",staticStyle:{"margin-top":"-30px"},attrs:{"span":"29","offset":3}},[_c('Courseware',{attrs:{"courseId":_vm.formData.courseId}})],1)],1):_vm._e(),_c('a-row',{staticClass:"rows"},[_c('a-col',{attrs:{"span":"20","offset":"4"}},[(_vm.$route.query.status == 1)?_c('a-button',{staticStyle:{"margin-right":"30px"},attrs:{"loading":_vm.loadingBtn,"type":"primary"},on:{"click":_vm.onStorageClick}},[_vm._v("暂存")]):_vm._e(),_c('a-button',{attrs:{"loading":_vm.loadingBtn},on:{"click":_vm.onSaveClick}},[_vm._v("保存")])],1)],1),_c('Modal',{ref:"Modal",on:{"ok":_vm.onModal}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }