<template>
  <div >
    <a-button type="primary" class="btn" @click="onOpenModal(1)">添加课件</a-button>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs :default-active-key="auditStatus" @change="callback">
        <a-tab-pane :key="1" tab="课程课件"></a-tab-pane>
        <a-tab-pane :key="2" tab="章节课件"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="tableColumns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <!-- 终端展示 -->
        <template slot="move" slot-scope="e, row, index">
          <template>
            <a v-show="((pageNum - 1) * pageSize + index + 1) != 1" @click="onMove(e,1)">前移</a>
            <span v-show="((pageNum - 1) * pageSize + index + 1) != 1 && ((pageNum - 1) * pageSize + index + 1) != total"> | </span>
            <a v-show="((pageNum - 1) * pageSize + index + 1) != total" @click="onMove(e,2)">后移</a>
            <a v-show="total == 1">-</a>
          </template>
        </template>

        <template slot="sort" slot-scope="item,row">
          <a-input-number id="inputNumber" :disabled="loading" v-model="item" :min="1" @change="onSortChange(item,row)" />
        </template>

        <!-- 是否可下载 -->
        <template slot="isDownload" slot-scope="e,row">
          <a-switch @change="onChangeFlag(row)" :checkedValue="1" :checked ="FunDownType(row.downloadFlag)" />
        </template>

        <template slot="operation" slot-scope="text">
          <div class="btn_router_link">
            <a @click="deleteData(text.id)"> 删除</a>
            <template > | </template>
            <a @click="onEdit(text)">编辑</a>
            <template > | </template>
            <a @click="onPreview(text.url)">预览</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 添加/编辑课件 -->
    <a-modal v-model="ModalShow" align="center" :title="addType == 1 ? '添加课件':'编辑课件'">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"
            ><span class="name all_required" style="line-height: 32px"
              >课件名称：</span
            ></a-col
          >
          <a-col :span="15">
            <a-input v-model="formData.name" placeholder="请填写课件名称" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"
            ><span class="name all_required" style="line-height: 32px"
              >课件类型：</span
            ></a-col
          >
          <a-col :span="15" class="col-right">
            <a-radio-group class="radio" v-model="formData.materialType">
              <a-radio :value="1">课程课件</a-radio>
              <a-radio :value="2">章节课件</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-show="formData.materialType == 2">
          <a-col :span="6" class="a-row-left"
            ><span class="name all_required" style="line-height: 32px"
              >选择章节：</span
            ></a-col
          >
          <a-col :span="15" class="col-right">
            <a-select
              style="width:100%"
              show-search
              allowClear
              placeholder="请选择章节"
              option-filter-prop="children"
              :filter-option="filterOption"
              v-model="formData.sectionId"
              >
                <a-select-option @click="onChapterClick(data.sectionName)" :value="data.sectionId"
                  v-for="data in selectList"
                  :data-val="data.sectionId"
                  :key="data.sectionId">{{data.sectionName}}
                </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"
            ><span class="name all_required" style="line-height: 32px"
              >上传课件：</span
            ></a-col
          >
          <a-col :span="15" class="col-right">
            <a-upload
              name="videoFileId"
              accept=".pdf" 
              :beforeUpload="beforeUpload"
              :showUploadList="false"
              :customRequest="customRequest"
            >
              <a-button ref="btn" v-show="addType == 1">
                <template v-if="uploadLoading">
                  <a-icon type="loading" />
                  <span>上传中</span>
                </template>
                <template v-else>
                  <a-icon type="upload" />
                  <span>{{formData.url?'重新上传':'选择课程文件'}}</span>
                </template>
              </a-button>
            </a-upload>
            <a-button v-show="addType == 2" @click="handleBeforeUpload">
              <a-icon type="upload" />
              <span>重新上传</span>
            </a-button>
            <p v-if="formData.url">
              <span>上传成功：</span>
              <span>{{FunFormat(formData.url)}}</span>
            </p>
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button @click="ModalShow = false">取消</a-button>
        <a-button type="primary" :loading="loading" @click="onSignOk">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns1 = [
  {
    title: "课件名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "修改时间",
    align: "center",
    dataIndex: "modifyTime",
  },
  // {
  //   title: "终端展示",
  //   align: "center",
  //   scopedSlots: { customRender: "move" },
  // },
  {
    title: "终端展示",
    align: "center",
    dataIndex: "materialSort",
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "是否可下载",
    align: "center",
    dataIndex: "downloadFlag",
    scopedSlots: { customRender: "isDownload" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "180px",
    scopedSlots: { customRender: "operation" },
  },
];
const columns2 = [
  {
    title: "课件名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "关联章节",
    align: "center",
    dataIndex: "sectionName",
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "修改时间",
    align: "center",
    dataIndex: "modifyTime",
  },
  {
    title: "终端展示",
    align: "center",
    dataIndex: "materialSort",
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "是否可下载",
    align: "center",
    dataIndex: "downloadFlag",
    scopedSlots: { customRender: "isDownload" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "180px",
    scopedSlots: { customRender: "operation" },
  },
];

import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: ['courseId'],
  // 数据对象
  data() {
    return {
      auditStatus:1, // 列表分类
      columns1, // 章节课件表头
      columns2, // 课程课件表头
      tableColumns: [],
      ModalShow: false,
      addType:1,   // 课件添加弹窗类型   1.添加  2.编辑

      loading:false,
      pageSize:10,
      pageNum:1,
      total:0,
      tableData: [],

      uploadLoading:false, // 文件上传状态

      // 添加/编辑 课件表单
      formData:{
        courseId:'',
        name:'',
        materialType: 1,
        sectionId:undefined,
        sectionName: '',
        url:''
      },

      filterOption:'',
      selectList:[],  // 章节列表
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },

    // 课件编辑上传文件前提醒
    handleBeforeUpload(e){
      let _this = this
      _this.$confirm({
        title: '选择文件后即完成课件更新，是否继续选择文件?',
        okText: '确认',
        onOk() {
          _this.$refs["btn"].$el.click()
        },
        onCancel() {
        },
      });
    },

    onEdit(e){
      this.formData.name = e.name
      this.formData.materialType = e.materialType
      this.formData.sectionId = (e.materialType == 1 ? undefined : e.sectionId)
      this.formData.sectionName = (e.materialType == 1 ? null : e.sectionName)
      this.formData.url = e.url
      this.formData.id = e.id
      this.onOpenModal(2)
    },

    onOpenModal(index){
      this.addType = index   // 1.添加  2.编辑
      this.formData.materialType = this.auditStatus  // 1.章节课件  2.课程课件
      this.ModalShow = true
    },
    // tab切换
    callback(e) {
      this.auditStatus = e;

      this.getManageList();
    },

    FunDownType(flag){
      return flag == 1 ? true : false
    },

    // 课件是否可下载
    onChangeFlag(e){
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/course/material/update",
        params: {
          id: e.id,
          materialType: e.materialType,
          downloadFlag: e.downloadFlag ? 0 : 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getManageList()
        } else {
          this.loading = false;
          this.$message.warning(res.message);
        }
      });
    },

    // 修改位置
    onSortChange(e,item) {
      item.materialSort = e;
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      let params = {
        id: item.id,
        materialSort: e
      }
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/course/updateSort",
            method: "PUT",
            params: params
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.getManageList();
              _this.$message.success("操作成功");
            }else{
              _this.$message.error(res.message);
            }
          });
      },600)
    },
    
    // 课程课件顺序上/下移
    onMove(e,i){
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/course/MoveUp/down",
        params: {
          courseId: this.formData.courseId,
          id: e.id,
          materialSort: e.materialSort,
          move: i,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.getManageList()
        }else{
          this.$message.error(res.message);
        }
      });
    },

    // 删除标签
    deleteData(id) {
      let _this = this
      this.$confirm({
        title: '确认是否删除该讲义?',
        okText: '确认',
        onOk() {
          _this.loading = true;
          _this.$ajax({
            url: '/hxclass-management/course/material/del/' + id,
            method: "DELETE",
          }).then((res) => {
            _this.loading = false;
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getManageList()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });
    },

    // 预览
    onPreview(url){
      window.open(url);
      // window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url);
    },

    // 格式化文件名称
    FunFormat(file){
      var dateLength = file.length;
      var ednStr = file.substring(dateLength-16,dateLength);
      var str = '.......' + ednStr
      return str
    },
    // 文件上传前钩子
    beforeUpload(file){
      if (!this.$regular.fileReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.fileReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      this.uploadLoading = true
      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          if(this.addType == 2){
            this.onUpdateFile(url)
          } else {
            this.formData.url = url
          }
        } else {
          this.$message.error("上传失败");
        }
        this.uploadLoading = false
      });
    },

    // 更新文件
    onUpdateFile(url){
      this.$ajax({
        method: "post",
        url: "/hxclass-management/course/material/update/url",
        params: {
          id: this.formData.id,
          url: url,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('文件更新成功');
          this.formData.url = url
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    
    // 关联章节选择
    onChapterClick(e){
      this.formData.sectionName = e
    },

    onSignOk(){
      if(!this.formData.name){
        this.$message.warning("请填写课件名称");
        return;
      }
      if(!this.formData.sectionId && this.formData.materialType == 2){
        this.$message.warning("请选择关联章节");
        return;
      }
      if(!this.formData.url){
        this.$message.warning("请上传课件文件");
        return;
      }


      // 课程课件不需要章节信息
      if(this.formData.materialType == 1){
        this.formData.sectionId = null
        this.formData.sectionName = null
      }

      let method = (this.addType == 1 ? "post" : "put")
      let url = (this.addType == 1 ? "/hxclass-management/course/material/insert" : "/hxclass-management/course/material/update")

      this.loading = true;
      this.$ajax({
        method: method,
        url: url,
        params: this.formData,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.formData = {
            courseId: this.formData.courseId,
            name:'',
            materialType: 1,
            sectionId:undefined,
            url:''
          }
          this.ModalShow = false
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/course/material/list",
        params: {
          courseId: this.formData.courseId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: this.auditStatus
        },
      }).then((res) => {
        this.loading = false;
        // 1 更换课件表头  2 更换章节表头
        this.tableColumns = (this.auditStatus == 1 ? columns1 : columns2);

        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },

    // 查询章节目录
    getSelectList() {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/course/material/getSectionInfo",
        params: {
          courseId:this.formData.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.selectList = res.data
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.tableColumns = columns1
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  beforeDestroy() {
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    courseId(){
      this.formData.courseId = this.courseId
      this.getSelectList()
      this.getManageList();
    },
    ModalShow(){
      if(this.addType == 2 && this.ModalShow == false){
        this.formData = {
          courseId: this.formData.courseId,
          name:'',
          materialType: 1,
          sectionId:undefined,
          url:''
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/ .left-box{
  white-space:nowrap;
}
.table-template {
  margin-top: 0;
}
.pictrue {
  width: 50px;
  height: 60px;
  background-repeat: no-repeat;
  object-fit: cover;
}
/deep/ .ant-modal-body {
  padding-bottom: 0;
}
.all_content_box {
  .a-row-item {
    margin-bottom: 20px;
    .a-row-left {
      margin-right: 10px;
    }
    .name {
      width: 100%;
      display: inline-block;
      text-align: right;
    }
    .right {
      width: 100%;
    }
  }
  .tips {
    font-size: 17px;
    color: #333;
    margin: 0 0 20px;
  }
}
/deep/ .ant-modal-footer {
  text-align: center;
  padding-bottom: 30px;
}

.radio{
  margin-top: 5px;
}
.col-right{
  text-align: left;
}

</style>
